import React from 'react'

import theme from '../../theme/theme'

class Unsubscribe extends React.Component {
	componentDidMount() {
		window.location.replace(theme.redirects.unsubscribe + '/' + window.location.search)
	}

	render () {
		return true
	}
}

export default Unsubscribe
